import rawAxios from "axios"
import store from "@/state/store"
import { notify } from "@kyvg/vue3-notification";
import router from "@/router";
import apiBaseUrl from "@/helpers/constant/serverUrl";



const Axios = rawAxios.create({
    baseURL: apiBaseUrl,
    headers: {
        'Authorization': 'Bearer ' + store.getters["auth/accessToken"],
    },
})

Axios.interceptors.response.use(
    (response) => {
        // const status = response.status;

        // Access the request method
        const {method} = response.config;

        if (method !== "get") {
            notify({
                type: "success",
                title: "Success",
                text: "Opération réussie",
            })
        }
        return response;
    },
    (error) => {
        // console.log(error.response.data)
        const responseStatus = error.response.status 
        const dataStatus = error.response.data.code
        const dataMessage = error.response.data.message
        
        if(responseStatus >= 500){
            notify({
                type: "error",
                title: "Erreur",
                text: "Une erreur est survenue",
            })
        } else if (dataStatus === 401 && (dataMessage === "Expired JWT Token" || dataMessage === "Invalid JWT Token")) {
            router.push({ name: "logout" })
            notify({
                type: "warn",
                title: "Erreur",
                text: "Session expirée, veuillez vous reconnecter",
            })
        } else if (dataStatus === 401 && dataMessage === "Invalid credentials.") {
            notify({
                type: "warn",
                title: "Attention!",
                text: dataMessage,
            })
        } else {
            // console.log(error.response.data)
            notify({
                type: "warn",
                title: "Attention!",
                text: error.response.data.detail,
            })
        }

        // Do something with the response error
        // You can handle errors or modify the error response here
        return Promise.reject(error);
    }
)

export default Axios