<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center">
            <p class="mb-0 text-muted">
              &copy; {{ new Date().getFullYear() }} MCP.

<!--              <i class="mdi mdi-heart text-danger"></i> by <a href="https://dhi-cm.com/">Digital House International</a>-->
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup>
</script>