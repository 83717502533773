//local
// export const serverUrl = 'http://127.0.0.1:8888'

//test
export const serverUrl = 'https://api.mcp.dhi-cm.com'

//test
// export const serverUrl = 'https://api.test.mcpfinances.org'

// prod
// export const serverUrl = 'https://api.mcpfinances.org'

export default serverUrl + '/api'